
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { FTextField } from 'fwkc4-vue'

export default Vue.extend({
  name: 'DocumentData',
  components: {
    FTextField
  },
  props: {
    showButtons: {
      type: Boolean,
      default: false
    },
    textareaClass: {
      type: String,
      default: 'no-buttons'
    }
  },
  data () {
    return {
      asunto: null,
      proceso: null,
      id: null,
      id_sgaim: null,
      motivo: null,
      metadatos: null,
      estado: null,
      transaction: null
    }
  },
  computed: {
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    ...mapGetters('user', [
      'isUsuarioExterno',
      'isSindicato'
    ]),
    getShowMetadata () {
      if (this.isSindicato === true) return false
      return true
    },
    getPasoActual () {
      return this.getCurrentTransaction.paso
    },
    hasMotivoRechazo () {
      return (this.getCurrentTransaction.motivo !== null && this.getCurrentTransaction.motivo !== undefined)
    },
    hasPerfilSindicato () {
      return this.isSindicato
    }
  },
  async mounted () {
    try {
      this.$store.dispatch('ui/showMask')
      this.setInitialData()
    } catch (err) {
      console.log(err)
    } finally {
      this.$store.dispatch('ui/hideMask')
    }
  },
  methods: {
    setInitialData () {
      this.transaction = this.getCurrentTransaction
      if (this.transaction !== null) {
        this.asunto = this.transaction.asunto
        this.proceso = this.transaction.proceso
        this.estado = this.transaction.estado.estadoInterno
        this.id = this.transaction.id
        this.id_sgaim = this.transaction.id_sgaim
        this.motivo = this.transaction.motivo
        if (this.transaction?.metadatos !== null && this.transaction?.metadatos !== undefined) {
          let allData = ''
          Object.keys(this.transaction.metadatos).map((key) => {
            allData += '<b>' + key + ': </b>' + this.transaction.metadatos[key] + '</br>'
          })
          this.metadatos = allData
          this.$refs.divMetadatos.innerHTML = this.metadatos
        }
      }
    }
  }
})
