
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { FTable, FButton, FIcon, FTextField } from 'fwkc4-vue'
import { requiredIf } from 'vuelidate/lib/validators'
import { sort } from '@/utils/utils'
import { removeBase64Header, toBase64 } from '@/utils/fileUtils'
import downloadDocument from '@/services/transactions/downloadDocument.management'
import downloadAct from '@/services/transactions/downloadAct.management'
import DocumentData from './DocumentData.vue'
import IconAction from '@/components/grid/IconAction.js'
import GenericConfirmedDialog from '@/views/common/dialogs/GenericConfirmedDialog.vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import GenericControlConfirmDialog from '@/views/common/dialogs/GenericControlConfirmDialog.vue'
import updateTransaction from '@/services/transactions/updateTransaction.management'

export default Vue.extend({
  name: 'Anexos',
  components: {
    DocumentData,
    FTable,
    FButton,
    FIcon,
    FTextField
  },
  props: {
    showButtons: {
      type: Boolean,
      default: false
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const usuario = this.$store.getters['user/getUser']
    return {
      asunto: null,
      tipoDocumento: null,
      nombreDocumento: null,
      metadatos: null,
      transaction: null,
      columnDefs: null,
      rowData: [],
      columnDefsActs: null,
      rowDataActs: [],
      layout: 'normal',
      rowSelection: 'single',
      stripped: true,
      strippedStr: 'true',
      visibleMenuButton: false,
      gridOptions: null,
      gridOptionsActs: null,
      frameworkComponents: {
        iconAction: IconAction
      },
      usuario: usuario,
      selectedFile: null,
      docDescription: null,
      isFirstLoad: true
    }
  },
  computed: {
    ...mapGetters('transactionDetail', [
      'getCurrentTransaction'
    ]),
    ...mapGetters('user', [
      'hasActs'
    ]),
    showDocumentGrid () {
      return this.rowData?.length > 0
    },
    showActsGrid () {
      return this.hasActs && this.rowDataActs?.length > 0
    },
    canAddDocument () {
      const perfilesPermitidos = ['UPFI0001', 'UPFI0002', 'UPFI0003', 'UPFI0007', 'UPFI0009']
      const isIncluded = perfilesPermitidos.some(element => this.usuario.profiles.includes(element))
      return this.getCurrentTransaction && this.getCurrentTransaction.flujo_id !== 131 && this.getCurrentTransaction.flujo_id !== 139 && isIncluded
    },
    btnAddDocumentDisabled () {
      return (!this.selectedFile || !this.docDescription)
    },
    btnSaveDisabled () {
      return !this.rowData.some(element => element.markForDeletion || element.pseudo_id)
    },
    btnCancelDisabled () {
      return !this.rowData.some(element => element.markForDeletion || element.pseudo_id)
    },
    fileName () {
      return this.selectedFile ? this.selectedFile.name : ''
    },
    rowDataFiltered () {
      return this.rowData.filter(item => !item.markForDeletion).sort(sort('name'))
    },
    texareaClass () {
      return this.showButtons ? 'buttons' : 'no-buttons'
    }
  },
  watch: {
    rowData: {
      handler (newValue) {
        if (!this.isFirstLoad) {
          this.$emit('onChangeAnexos', newValue)
        }
      },
      deep: true
    }
  },
  validations () {
    return {
      docDescription: {
        required: requiredIf(() => {
          return this.selectedFile
        })
      }
    }
  },
  async mounted () {
    try {
      this.$store.dispatch('ui/showMask')
      this.setColumns()
      this.setColumnsActs()
      this.setInitialData()
    } catch (err) {
      console.log(err)
    } finally {
      this.$store.dispatch('ui/hideMask')
    }
  },
  methods: {
    getFile () {
      document.getElementById('upfile').click()
    },
    deleteFile () {
      (document.getElementById('upfile') as HTMLInputElement).value = ''
      this.selectedFile = null
    },
    onSelectFile (evt) {
      this.selectedFile = evt.target.files[0]
      if (this.selectedFile.size > 7340032) {
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            secondModal: true,
            text: 'No se pueden adjuntar ficheros con un tamaño mayor de 7MB'
          }
        })
        this.resetNewDoc()
      }
    },
    async addDocument () {
      let fichero = await toBase64(this.selectedFile)
      fichero = removeBase64Header(fichero, null)
      const documentCreate = {
        descripcion: this.docDescription,
        principal: false,
        metadatos: {},
        file_name: this.selectedFile.name,
        pseudo_id: new Date().valueOf(),
        content: fichero,
        firma: false
      }
      console.log(documentCreate)
      this.rowData.push(documentCreate)
      this.$v.$touch()
      this.resetNewDoc()
    },
    deleteDocument (deleteDoc) {
      if (deleteDoc.id) {
        this.rowData = this.rowData.map(element => {
          if (element.id === deleteDoc.id) {
            return {
              id: element.id,
              markForDeletion: true
            }
          }
          return element
        })
      } else {
        this.rowData.splice(this.rowData.findIndex((item) => item.pseudo_id === deleteDoc.pseudo_id), 1)
      }
      this.$v.$touch()
    },
    resetNewDoc () {
      (document.getElementById('upfile') as HTMLInputElement).value = ''
      this.selectedFile = null
      this.docDescription = null
    },
    cancelChanges () {
      this.$emit('onCancelChanges')
    },
    confirmSaveChanges () {
      this.$emit('onConfirmSaveChanges')
    },
    setInitialData () {
      this.transaction = this.getCurrentTransaction
      if (this.transaction !== null) {
        if (this.transaction?.documents?.length > 0) {
          // Evitar copia por referencia y mantener los datos iniciales
          this.rowData = this.transaction.documents.map(object => ({ ...object }))
        }
        if (this.transaction?.evidences?.length > 0) {
          this.rowDataActs = this.transaction.evidences
        }
      }
      setTimeout(() => {
        this.isFirstLoad = false
      }, 100)
    },
    setColumns () {
      this.gridOptions = {
        defaultColDef: {
          sortable: false,
          resizable: true,
          canEdit: false,
          minWidth: 80,
          suppressMenu: true
        },
        headerHeight: 50,
        suppressCellSelection: true,
        onGridSizeChanged: event => { event.api.sizeColumnsToFit() }
      }
      this.columnDefs = [{
        headerName: 'Tipo Documento',
        field: 'name',
        suppressMenu: true,
        menuTabs: [],
        headerClass: 'ag-header-group-cell-in-group',
        valueFormatter: params => {
          if (params?.data?.descripcion) {
            return params?.data?.descripcion
          }
          return ''
        }
      }, {
        headerName: 'Documento',
        field: 'delete',
        headerClass: 'ag-header-group-cell-in-group',
        minWidth: 60,
        width: 60,
        filter: false,
        menuTabs: [],
        sortable: false,
        suppressMovable: true,
        suppressMenu: true,
        cellRendererSelector: cellContext => {
          if (this.canAddDocument) {
            return {
              component: 'columnAction',
              params: {
                actions: [{
                  props: { name: this.getIconName(cellContext.data.content_type), disabled: !cellContext.data.id },
                  events: {
                    click: () => {
                      this.downloadDocument(cellContext.rowIndex)
                    }
                  }
                },
                {
                  props: { name: 'trash-alt', disabled: cellContext.data.principal, color: 'red' },
                  events: {
                    click: () =>
                      this.deleteDocument(cellContext.data)
                  }
                }]
              }
            }
          }
          return {
            component: 'columnAction',
            params: {
              actions: [{
                props: { name: this.getIconName(cellContext.data.content_type), disabled: !cellContext.data.id },
                events: {
                  click: () => {
                    this.downloadDocument(cellContext.rowIndex)
                  }
                }
              }]
            }
          }
        }
      }]
    },
    getIconName (content_type) {
      if (content_type) {
        if (content_type.includes('pdf')) {
          return 'file-pdf'
        } else if (content_type.includes('excel')) {
          return 'file-excel'
        } else if (content_type.includes('word')) {
          return 'file-word'
        } else if (content_type.includes('txt') || content_type.includes('text')) {
          return 'file-alt'
        } else if (content_type.includes('csv')) {
          return 'file-csv'
        } else if (content_type.includes('zip')) {
          return 'file-archive'
        } else if (content_type.includes('jpg') || content_type.includes('jpeg')) {
          return 'file-image'
        }
        return 'file-download'
      } else {
        return 'file-download'
      }
    },
    setColumnsActs () {
      this.gridOptionsActs = {
        defaultColDef: {
          sortable: false,
          resizable: true,
          canEdit: false,
          minWidth: 80,
          suppressMenu: true
        },
        headerHeight: 50,
        suppressCellSelection: true,
        onGridSizeChanged: event => { event.api.sizeColumnsToFit() }
      }
      this.columnDefsActs = [{
        headerName: 'Acta',
        field: 'name',
        suppressMenu: true,
        menuTabs: [],
        headerClass: 'ag-header-group-cell-in-group',
        valueFormatter: params => {
          if (params?.data?.descripcion_evidencia) {
            if (params?.data?.estado === 'MINUTE_SEND_CONTRACT_AVAILABLE') {
              return params?.data?.descripcion_evidencia + ' (envío)'
            }
            return params?.data?.descripcion_evidencia
          }
          return ''
        }
      }, {
        headerName: 'Documento',
        field: 'delete',
        headerClass: 'ag-header-group-cell-in-group',
        minWidth: 60,
        width: 60,
        filter: false,
        menuTabs: [],
        sortable: false,
        suppressMovable: true,
        suppressMenu: true,
        cellRendererSelector: (cellContext) => {
          return {
            component: 'iconAction',
            params: {
              props: {
                iconName: 'file-pdf',
                rowIndex: cellContext.rowIndex,
                onClick: (rowIndex) => {
                  this.downloadActs(rowIndex)
                }
              }
            }
          }
        }
      }]
    },
    downloadDocument (rowIndex) {
      let text = ' El anexo se abrirá en otra pestaña del navegador, esta operación puede tardar varios segundos en función del tamaño del documento. ¿Confirmas que deseas abrirlo?'
      if (this.rowData[rowIndex]?.content_type !== 'application/pdf') {
        text = 'Esta operación puede tardar varios segundos en función del tamaño del documento. ¿Confirmas que deseas descargar el anexo?'
      }
      this.$store.dispatch('ui/openDialog', {
        component: GenericControlConfirmDialog,
        props: {
          text: text,
          secondModal: true,
          functionCb: () => {
            this.onDownloadDocumentConfirmed(rowIndex)
          }
        }
      })
    },
    downloadActs (rowIndex) {
      let text = ' El acta se abrirá en otra pestaña del navegador, esta operación puede tardar varios segundos en función del tamaño del documento. ¿Confirmas que deseas abrirlo?'
      if (this.rowData[rowIndex]?.content_type !== 'application/pdf') {
        text = 'Esta operación puede tardar varios segundos en función del tamaño del documento. ¿Confirmas que deseas descargar el acta? '
      }
      this.$store.dispatch('ui/openDialog', {
        component: GenericControlConfirmDialog,
        props: {
          text: text,
          secondModal: true,
          functionCb: () => {
            this.onDownloadActConfirmed(rowIndex)
          }
        }
      })
    },
    async onDownloadDocumentConfirmed (rowIndex) {
      if (rowIndex > -1) {
        const document = this.rowData[rowIndex]
        let response: any = null
        try {
          const params = {
            transaccion_id: this.transaction.id,
            documento_id: document.id,
            history: (this.transaction.historico === true) ? 'yes' : null,
            year: this.transaction.anyo
          }
          response = await downloadDocument(params)
          if (response?.stream) {
            this.exportToFile(response, document.descripcion)
          } else {
            this.$store.dispatch('ui/openDialog', {
              component: GenericErrorDialog,
              props: {
                text: 'La descarga del anexo ha fallado',
                secondModal: true
              }
            })
          }
        } catch (err) {
          console.log(err)
          this.$store.dispatch('ui/openDialog', {
            component: GenericErrorDialog,
            props: {
              text: 'Se ha producido un error, inténtalo de nuevo más tarde'
            }
          })
        } finally {
          this.$store.dispatch('ui/hideMask')
        }
      }
    },
    async onDownloadActConfirmed (rowIndex) {
      if (rowIndex > -1) {
        const document = this.rowDataActs[rowIndex]
        let response: any = null
        try {
          const params = {
            transaccion_id: this.transaction.id,
            documento_id: document.id,
            history: (this.transaction.historico === true) ? 'yes' : null,
            year: this.transaction.anyo
          }
          response = await downloadAct(params)
          if (response?.stream) {
            this.exportToFile(response, document.descripcion_evidencia)
          } else {
            this.$store.dispatch('ui/openDialog', {
              component: GenericErrorDialog,
              props: {
                text: 'La descarga del acta ha fallado',
                secondModal: true
              }
            })
          }
        } catch (err) {
          console.log(err)
          this.$store.dispatch('ui/openDialog', {
            component: GenericErrorDialog,
            props: {
              text: 'Se ha producido un error, inténtalo de nuevo más tarde'
            }
          })
        } finally {
          this.$store.dispatch('ui/hideMask')
        }
      }
    },
    async exportToFile (data, name) {
      try {
        if (data.mimeType === 'application/pdf') {
          const objectURL = window.URL.createObjectURL(data.stream)
          window.open(objectURL, '_blank')
        } else {
          const objectURL = window.URL.createObjectURL(data.stream)
          const anchor = document.createElement('a')
          anchor.href = objectURL
          anchor.download = name
          anchor.click()
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'Se ha producido un error, inténtalo de nuevo más tarde'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    }
  }
})
