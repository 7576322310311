import { xhr } from '@/api/config/Repository'
import { AxiosResponse } from 'axios'
import CreateUserRequestDTO from '../dto/CreateUserRequestDTO'
import SearchUserRequestDTO from '../dto/SearchUserRequestDTO'
import CreateUserResponseDTO from '../dto/CreateUserResponseDTO'
import SearchUserResponseDTO from '../dto/SearchUserResponseDTO'
import UpdateUserRequestDTO from '../dto/UpdateUserRequestDTO'
import UpdateUserResponseDTO from '../dto/UpdateUserResponseDTO'
import DeleteUserRequestDTO from '../dto/DeleteUserRequestDTO'
import DeleteUserResponseDTO from '../dto/DeleteUserResponseDTO'
import GetUserGDPRRequestDTO from '../dto/GetUserGDPRRequestDTO'
import GetUserGDPRResponseDTO from '../dto/GetUserGDPRResponseDTO'
import GetUserGroupsRequestDTO from '../dto/GetUserGroupsRequestDTO'
import GetUserGroupsResponseDTO from '../dto/GetUserGroupsResponseDTO'
import UpdateUserGDPRRequestDTO from '../dto/UpdateUserGDPRRequestDTO'
import UpdateUserGDPRResponseDTO from '../dto/UpdateUserGDPRResponseDTO'
import AdministrationRepository from '../AdministrationRepository'
import env from '@/services/environment'
import store from '@/store'
import ExportUserResponseDTO from '../dto/ExportUserResponsetDTO'

export default class AdministrationRepositoryImpl implements AdministrationRepository {
  getDefaultHeaders (): any {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
  }

  createExternalUser (params: CreateUserRequestDTO): Promise<AxiosResponse<CreateUserResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), params, { headers })
  }

  getUserGDPR (params: GetUserGDPRRequestDTO): Promise<AxiosResponse<GetUserGDPRResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/users/gdpr/' + params?.id
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  getUserGroups (params: GetUserGroupsRequestDTO): Promise<AxiosResponse<GetUserGroupsResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/administration/groups?isAdam=' + params?.isAdam
    const headers = this.getDefaultHeaders()
    return xhr.get(msPFDUrl.concat(endpoint), { headers })
  }

  updateExternalUser (params: UpdateUserRequestDTO): Promise<AxiosResponse<UpdateUserResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/' + encodeURIComponent(params?.request?.email)
    const headers = {
      'Content-Type': 'application/merge-patch+json',
      'Access-Control-Allow-Origin': '*',
      version: env.getEnvVariable('VUE_APP_RELEASE_VERSION_TAG'),
      username: store.getters['user/getUsername']
    }
    const finalParms: UpdateUserRequestDTO = { ...params }
    delete finalParms.request.email
    return xhr.patch(msPFDUrl.concat(endpoint), finalParms, { headers })
  }

  updateUserGDPR (params: UpdateUserGDPRRequestDTO): Promise<AxiosResponse<UpdateUserGDPRResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/users/gdpr/update'
    const headers = this.getDefaultHeaders()
    return xhr.patch(msPFDUrl.concat(endpoint), params, { headers })
  }

  deleteExternalUser (params: DeleteUserRequestDTO): Promise<AxiosResponse<DeleteUserResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/' + encodeURIComponent(params?.email)
    const headers = this.getDefaultHeaders()
    return xhr.delete(msPFDUrl.concat(endpoint), { headers })
  }

  searchExternalUsers (params: SearchUserRequestDTO): Promise<AxiosResponse<SearchUserResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/search'
    const headers = this.getDefaultHeaders()
    return xhr.post(msPFDUrl.concat(endpoint), params, { headers })
  }

  exportExternalUsers (params: SearchUserRequestDTO): Promise<AxiosResponse<ExportUserResponseDTO>> {
    const msPFDUrl = env.getEnvVariable('VUE_APP_ROOT_API')
    const endpoint = '/ui/externalUsers/search/export'
    const headers = this.getDefaultHeaders()
    const responseType = 'blob'
    return xhr.post(msPFDUrl.concat(endpoint), params, { headers, responseType })
  }
}
