
import Vue from 'vue'
import env from '@/services/environment'
import MenuComplete from '@/components/MenuComplete.vue'
import MaskLoading from '@/components/Mask.vue'
import AuthServiceFactory from '@/services/auth/AuthServiceFactory'
import GenericConfirmedDialog from '@/views/common/dialogs/GenericConfirmedDialog.vue'
import { StateUiMask } from './store/modules/ui/types'
import getUserGDPR from '@/services/administration/getUserGDPR.management'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import InfoLegal from '@/components/dialogs/infoLegal/InfoLegal.vue'
import UserChangePasswordDialog from '@/components/dialogs/users/UserChangePasswordDialog.vue'
import UserLogoutDialog from '@/components/dialogs/users/UserLogoutDialog.vue'
import VueMobileDetection from 'vue-mobile-detection'
import { getTransactionDetails } from '@/utils/utils'
Vue.use(VueMobileDetection)

export default Vue.extend({
  name: 'App',
  components: {
    MenuComplete,
    MaskLoading
  },
  data () {
    return {
      isMobileMode: false,
      isLogin: false,
      authService: AuthServiceFactory.getInstance(),
      username: ''
    }
  },
  computed: {
    contentClass (): Record<string, boolean> {
      return {
        content: !this.isMobileMode && this.isLogin,
        'content-mobile': this.isMobileMode && this.isLogin
      }
    },
    dialogInfo (): any {
      return this.$store.getters['ui/dialogInfo']
    },
    secondDialogInfo (): any {
      return this.$store.getters['ui/secondDialogInfo']
    },
    maskInfo (): StateUiMask {
      return this.$store.getters['ui/maskInfo']
    },
    cachedComponents () {
      return this.$store.getters['ui/openedTabs']
    },
    isUserLoged () {
      return this.isLogin
    },
    useTabs (): boolean {
      console.log(env.getEnvVariable('VUE_APP_USE_TABS'))
      return env.getEnvVariable('VUE_APP_USE_TABS').toUpperCase() === 'TRUE'
    }
  },
  watch: {
    contentClass (value) {
      if (value.content) {
        this.setHeight()
      }
    }
  },
  async mounted () {
    this.subscribeEvents()
    try {
      this.$store.dispatch('ui/showMask')
      await this.authService.login.checkSession()
    } catch (err) {
      console.error('Error al chequear la session', err)
    } finally {
      this.$store.dispatch('ui/hideMask')
    }
  },
  methods: {
    setHeight () {
      // const cabeceraHeight = 101
      // const html = document.getElementsByTagName('html')[0]
      // const divMaster = document.getElementById('divMaster')
      // console.log(`${html.offsetHeight}px`)
    },
    onResize ({ isMobileMode }: { isMobileMode: boolean }) {
      this.isMobileMode = isMobileMode
    },
    onLogoutByMenu () {
      this.$store.dispatch('ui/openDialog', {
        component: UserLogoutDialog
      })
    },
    onCloseDialog () {
      this.$store.dispatch('ui/closeDialog')
    },
    onCloseSecondDialog () {
      this.$store.dispatch('ui/closeSecondDialog')
    },
    subscribeEvents () {
      const eventMap: Record<string, Function> = {
        'login-success': this.onLoginSuccess,
        'logout-success': this.onLogoutSuccess,
        logoutapp: this.onLogoutApp,
        refreshtoken: this.onRefreshToken,
        warninvalidrefresh: this.onWarnInvalidRefresh,
        forbiddenaccess: this.onForbiddenAccess,
        errorinvalidrefresh: this.onErrorInvalidRefresh,
        failedrefresh: this.onFailedRefresh,
        saveUserContext: this.onSaveUserContext
      }

      Object.keys(eventMap).forEach((name: string) => {
        document.addEventListener(name, () => {
          eventMap[name]()
        })
      })
    },
    onLoginSuccess () {
      this.isLogin = true
    },
    onLogoutSuccess () {
      this.isLogin = false
      this.$store.dispatch('user/logoutUser')
      this.$router.push('/login')
      /* this.$nextTick(() => {
        this.$store.dispatch('transactionRef/updateTransactionRef', null)
      }) */
    },
    onLogoutApp () {
      this.onCloseDialog()
      this.onCloseSecondDialog()
      this.authService.logout.logout()
      /* this.$nextTick(() => {
        this.$store.dispatch('transactionRef/updateTransactionRef', null)
      }) */
    },
    onRefreshToken () {
      this.authService.refresh.execute()
    },
    onWarnInvalidRefresh () {
      console.log('Evento de warning invalid refresh')
    },
    onForbiddenAccess () {
      console.log('Evento de forbidden access')
      // Inicio - 09-08-2022 - Redirigir a Forbidden
      window.location.replace(window.location.origin + '/forbidden')
      // Fin- 09-08-2022 - Redirigir a Forbidden
    },
    onErrorInvalidRefresh () {
      this.onCloseDialog()
      this.onCloseSecondDialog()
      this.authService.logout.logout()
    },
    onFailedRefresh () {
      this.onCloseDialog()
      this.onCloseSecondDialog()
      this.authService.logout.logout()
    },
    onSaveUserContext () {
      const user = this.authService.user
      this.username = user.username
      this.$store.dispatch('user/loginUser', { params: user })
      this.checkUserGDPRPass(user.username)
    },
    async checkUserGDPRPass (username: string) {
      this.$store.dispatch('ui/showMask')
      let response: any = null
      try {
        const params = {
          id: username
        }
        response = await getUserGDPR(params)
        if (response?.activo === false) {
          this.$store.dispatch('ui/openDialog', {
            component: GenericConfirmedDialog,
            props: {
              text: 'El usuario no está activo en la aplicación de Firma Digital',
              functionCb: this.onLogoutApp
            }
          })
        } else if (response?.fecha_gdpr === null ||
            response?.fecha_gdpr === '' ||
            response?.fecha_gdpr === undefined) {
          // force user to accept GDPR
          this.$store.dispatch('ui/openDialog', {
            component: InfoLegal,
            props: {
              force: true,
              functionCb: () => {
                this.userChangePassword()
              },
              continueCb: () => {
                this.loadInitialViewByProfile()
              }
            }
          })
        } else if (response?.cambiar_clave === true) {
          // force user to change pass
          this.userChangePassword(username)
        } else {
          // start with the initial view
          this.loadInitialViewByProfile()
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: 'No se ha podido comprobar la aceptación de politicas del usuario'
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    loadInitialViewByProfile () {
      if (this.$isMobile() === false) {
        const transactionRef = this.$store.getters['transactionRef/transactionRef']
        const isUsuarioExterno = this.$store.getters['user/isUsuarioExterno']
        const isSindicato = this.$store.getters['user/isSindicato']
        if (transactionRef) {
          const selectedTransaction = {
            id: transactionRef.ref,
            history: transactionRef.history,
            year: transactionRef.year
          }
          getTransactionDetails(selectedTransaction, null, (isUsuarioExterno || isSindicato), '')
          return
        }
      }
      const profiles = this.$store.getters['user/getProfiles']
      const isSindicato = this.$store.getters['user/isSindicato']
      const isAdministradorUsuarios = this.$store.getters['user/isAdministradorUsuarios']
      if (isSindicato === true) {
        const event = new CustomEvent('eventOpenTab', {
          detail: {
            tabName: 'Todos'
          }
        })
        document.dispatchEvent(event)
      } else if (isAdministradorUsuarios === false || (isAdministradorUsuarios === true && profiles.length > 1)) {
        const event = new CustomEvent('eventOpenTab', {
          detail: {
            tabName: 'Pendientes'
          }
        })
        document.dispatchEvent(event)
      } else if (isAdministradorUsuarios === true && profiles.length === 1) {
        const event = new CustomEvent('eventOpenTab', {
          detail: {
            tabName: 'UsuariosExternos'
          }
        })
        document.dispatchEvent(event)
      }
    },
    userChangePassword () {
      this.$store.dispatch('ui/openDialog', {
        component: UserChangePasswordDialog
      })
    }
  }
})
