
import Vue from 'vue'
import BasicDialog from '@/components/dialogs/BasicDialog.vue'
import MobileDialog from '@/components/dialogs/MobileDialog.vue'
import changepwdExternalUser from '@/services/users/changepwdExternalUser.management'
import GenericControlConfirmDialog from '@/views/common/dialogs/GenericControlConfirmDialog.vue'
import GenericConfirmedDialog from '@/views/common/dialogs/GenericConfirmedDialog.vue'
import GenericErrorDialog from '@/views/common/dialogs/GenericErrorDialog.vue'
import { mapGetters } from 'vuex'
import { FButton, FTextField } from 'fwkc4-vue'
import { validatePassword } from '@/utils/validations'
import { required, sameAs } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: 'UserChangePasswordDialog',
  components: {
    BasicDialog,
    MobileDialog,
    FButton,
    FTextField
  },
  props: {
    functionCb: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      // data
      password: null,
      newpassword: null,
      newpasswordrepeat: null,
      errorMessages: {
        required: this.$t('validations.required'),
        sameAsPassword: this.$t('validations.samePassword'),
        validatePassword: this.$t('validations.invalidFormat')
      }
    }
  },
  validations (): any {
    return {
      password: {
        required: required
      },
      newpassword: {
        required: required,
        validatePassword: validatePassword
      },
      newpasswordrepeat: {
        required: required,
        sameAsPassword: sameAs('newpassword')
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'getUsername'
    ]),
    getDisableChange () {
      return this.$v.$invalid
    }
  },
  methods: {
    onCancel () {
      this.$emit('closed')
    },
    confirmChange () {
      this.$store.dispatch('ui/openDialog', {
        component: GenericControlConfirmDialog,
        props: {
          text: 'Se modificará la contraseña, ¿deseas continuar?',
          secondModal: true,
          functionCb: () => {
            this.onChange()
          }
        }
      })
    },
    async onChange () {
      this.$store.dispatch('ui/showMask')
      let response: any = null
      try {
        const params = {
          email: this.getUsername,
          password: this.password,
          newpassword: this.newpassword
        }
        response = await changepwdExternalUser(params)
        if (response?.message === 'password cambiada') {
          this.gdprAccepted = true
          this.$store.dispatch('ui/openDialog', {
            component: GenericConfirmedDialog,
            props: {
              text: 'La contraseña ha sido modificada',
              functionCb: () => {
                this.onCloseDialog()
              }
            }
          })
        }
      } catch (err) {
        this.$store.dispatch('ui/hideMask')
        this.$store.dispatch('ui/openDialog', {
          component: GenericErrorDialog,
          props: {
            text: err?.response?.data?.message
          }
        })
      } finally {
        this.$store.dispatch('ui/hideMask')
      }
    },
    onCloseDialog () {
      this.$emit('closed')
    }
  }
})
